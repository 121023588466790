export default function AboutSection() {
  return (
    <div className="section-content">
      <h2>About</h2>
      <br/>
      <div className="section-group">
        {/* <div className="links-group">
          <h4>Platform</h4>
          <a href="/features">Features</a>
          <a href="/monitoring">Monitoring</a>
          <a href="/removal">Removal</a>
          <a href="/pricing">Pricing</a>
        </div>
        <br/><br/> */}
        <div className="links-group">
          <h4>The Team</h4>
          <a href="/about">Who We Are</a>
          <a href="/about/story">Our Story</a>
        </div>
        <br/><br/>
        <div className="links-group">
          <h4>Learn More</h4>
          <a href="/faq">FAQ's</a>
          <a href="/process">Our Process</a>
        </div>
      </div>
    </div>
  );
} 